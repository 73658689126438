import { Avatar, Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Callback = () => {
    const search = useLocation().search;
    const [users, setData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const code = new URLSearchParams(search).get('code');

        if (code) {
            fetch(`${process.env.REACT_APP_API_URL}/auth/callback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code }),
                credentials: 'include', 
            })
            .then(response => response.json())
            .then(data => {
                setData(data)
                // Handle the user data or redirect to another page
                navigate(`/guilds`);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }, [search]);

    return (
        <Box>
        {users ? (
            <div>
                <Avatar src={`https://cdn.discordapp.com/avatars/${users?.user?.id}/${users?.user?.avatar}.webp`} />
                <p>{ users?.user?.global_name }</p>
            </div>
          ) : (
            <CircularProgress />
          )}
        </Box>
    );
};

export default Callback;
