import { Avatar, Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

const GuildsSelection = () => {
    const [guilds, setGuilds] = useState(null);
    const navigate = useNavigate();  // Hook zum Navigieren verwenden

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/guilds`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include', 
        })            
        .then(response => response.json())
        .then(data => {
            // Filter the guilds where the user has 'MANAGE_GUILD' permission (0x20)
            const manageableGuilds = data.filter(guild => {
                const permissions = BigInt(guild.permissions_new || guild.permissions);
                return (permissions & BigInt(0x20)) === BigInt(0x20);
            });

            setGuilds(manageableGuilds);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }, []);

    // Funktion zum Navigieren zur Guild-Seite
    const handleGuildClick = (guildId) => {
        navigate(`/workers/${guildId}`);
    };

    return (
        <Fragment>
            <Navbar />
            <Box padding={8}>
                {guilds ? (
                    <Grid container spacing={2}>
                        {guilds.map((guild) => (
                            <Grid item xs={12} sm={6} md={4} key={guild.id}>
                                <Box 
                                    display="flex" 
                                    flexDirection="column" 
                                    alignItems="center" 
                                    p={2} 
                                    border={1} 
                                    borderRadius={8} 
                                    onClick={() => handleGuildClick(guild.id)} // OnClick Event hinzufügen
                                    sx={{ cursor: 'pointer', transition: '0.3s', '&:hover': { backgroundColor: '#4d5157' } }} // Optional: Hover-Effekt
                                >
                                    {guild.icon ? (
                                        <Avatar
                                            src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.webp`}
                                            sx={{ width: 64, height: 64 }}
                                        />
                                    ) : (
                                        <Avatar sx={{ width: 64, height: 64 }}>
                                            {guild.name[0]} {/* Falls kein Icon vorhanden ist */}
                                        </Avatar>
                                    )}
                                    <Typography variant="h6" align="center" mt={2}>
                                        {guild.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </Fragment>
    );
};

export default GuildsSelection;