import { Button } from "@mui/material";
import { Fragment } from "react";

const Home = () => {
    const onSignUp = () => {
        const clientId = '1163213715320082494';
        const redirectUri = encodeURIComponent(`${process.env.REACT_APP_URL}/callback`);
        const responseType = 'code';
        const scope = 'identify email guilds';
    
        window.location.href = `https://discord.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;
      };

    return (
        <Fragment>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh',  // Höhe des Ansichtsfensters
                margin: 0         // Entfernen von Standard-Margin
            }}>
                <img src="/header-logo.png" alt="Logo" style={{ height: '100px' }} />
                <Button variant="outlined" onClick={onSignUp} sx={{ fontSize: 20, margin: 3 }}>Login</Button>
            </div>
        </Fragment>
    );
};
 
export default Home;